<template>
  <img :src="src" :alt="props.badge" :style="`height: ${size}`" />
</template>

<script setup>
import unranked from '~/assets/svg/badges/unranked.svg';

import copper1 from '~/assets/svg/badges/copper1.svg';
import copper2 from '~/assets/svg/badges/copper2.svg';
import copper3 from '~/assets/svg/badges/copper3.svg';

import silver1 from '~/assets/svg/badges/silver1.svg';
import silver2 from '~/assets/svg/badges/silver2.svg';
import silver3 from '~/assets/svg/badges/silver3.svg';

import quartz1 from '~/assets/svg/badges/quartz1.svg';
import quartz2 from '~/assets/svg/badges/quartz2.svg';
import quartz3 from '~/assets/svg/badges/quartz3.svg';

import ruby1 from '~/assets/svg/badges/ruby1.svg';
import ruby2 from '~/assets/svg/badges/ruby2.svg';
import ruby3 from '~/assets/svg/badges/ruby3.svg';

import sapphire1 from '~/assets/svg/badges/sapphire1.svg';
import sapphire2 from '~/assets/svg/badges/sapphire2.svg';
import sapphire3 from '~/assets/svg/badges/sapphire3.svg';

import ivory1 from '~/assets/svg/badges/ivory1.svg';
import ivory2 from '~/assets/svg/badges/ivory2.svg';
import ivory3 from '~/assets/svg/badges/ivory3.svg';

import gold1 from '~/assets/svg/badges/gold1.svg';
import gold2 from '~/assets/svg/badges/gold2.svg';
import gold3 from '~/assets/svg/badges/gold3.svg';

import emerald1 from '~/assets/svg/badges/emerald1.svg';
import emerald2 from '~/assets/svg/badges/emerald2.svg';
import emerald3 from '~/assets/svg/badges/emerald3.svg';

import diamond1 from '~/assets/svg/badges/diamond1.svg';
import diamond2 from '~/assets/svg/badges/diamond2.svg';
import diamond3 from '~/assets/svg/badges/diamond3.svg';

import legend1 from '~/assets/svg/badges/legend1.svg';
import legend2 from '~/assets/svg/badges/legend2.svg';
import legend3 from '~/assets/svg/badges/legend3.svg';

const props = defineProps({
  badge: String,
  size: String,
});

const src = computed(() => svgMap.value[props.badge]);
const size = computed(() => sizeMap.value[props.size]);

const svgMap = computed(() => ({
  unranked: unranked,
  copper1: copper1,
  copper2: copper2,
  copper3: copper3,

  silver1: silver1,
  silver2: silver2,
  silver3: silver3,

  quartz1: quartz1,
  quartz2: quartz2,
  quartz3: quartz3,

  ruby1: ruby1,
  ruby2: ruby2,
  ruby3: ruby3,

  sapphire1: sapphire1,
  sapphire2: sapphire2,
  sapphire3: sapphire3,

  ivory1: ivory1,
  ivory2: ivory2,
  ivory3: ivory3,

  gold1: gold1,
  gold2: gold2,
  gold3: gold3,

  emerald1: emerald1,
  emerald2: emerald2,
  emerald3: emerald3,

  diamond1: diamond1,
  diamond2: diamond2,
  diamond3: diamond3,

  legend1: legend1,
  legend2: legend2,
  legend3: legend3,
}));

const sizeMap = computed(() => ({
  xxs: '12px',
  xs: '20px',
  sm: '50px',
  md: '120px',
  lg: '250px',
  xl: '350px',
}));
</script>
